import { toast } from 'react-hot-toast'
import { HttpError, HttpStatus } from 'services/HttpService'
import React from 'react'

export const toRem = (value: number): string => {
  const valueInRem = value / 16

  return `${valueInRem}rem`
}

export const getFromStorage = (key: string) => {
  return localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key) || '')
    : ''
}

export const removeFromStorage = (key: string) => {
  return localStorage.getItem(key) ? localStorage.removeItem(key) : false
}

export const saveToStorage = (key: string, value: string) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const Roles = [

  { value: 'Psychotherapist', text: 'Psychotherapist' },
  { value: 'Clinical Psychologist', text: 'Clinical Psychologist' },
  { value: 'Mental Health Nurse', text: 'Mental Health Nurse' },
  { value: 'Principal Counselor', text: 'Principal Counselor' },
  { value: 'Therapist', text: 'Therapist' },
  { value: 'Psychologist', text: 'Psychologist' },
  { value: 'Relationship Coach', text: 'Relationship Coach' },
  {
    value: 'Cognitive Behavioural therapist',
    text: 'Cognitive Behavioural therapist',
  },
  { value: 'Counselor', text: 'Counselor' },
  { value: 'Psychologist & therapist', text: 'Psychologist & therapist' },
  { value: 'Others', text: 'Others' },
  {value: "Psychiatrist", text: "Psychiatrist"}
]
export const Gender = [

  { value: 'male', text: 'Male' },
  { value: 'female', text: 'Female' },
  { value: 'others', text: 'Others' },
  { value: 'prefer-not-to-say', text: 'Prefer not to say' },

]

export enum MtToastPosition {
  TOPRIGHT = 'top-right',
  TOPLEFT = 'top-left',
  TOPCENTER = 'top-center',
  BOTTOMLEFT = 'bottom-left',
  BOTTOMRIGHT = 'bottom-right',
  BOTTOMCENTER = 'bottom-center',
}

export interface MtToastProps {
  msg: string
  position?: MtToastPosition
}

let mtToastStyle = {
  style: {
    fontSize: '15px',
    fontFamily: 'Plus Jakarta Sans',
  },
}

export const TherapistToast = {
  success: ({ msg, position = MtToastPosition.TOPRIGHT }: MtToastProps) =>
    toast.success(msg, {
      position,
      ...mtToastStyle,
    }),

  error: ({ msg, position = MtToastPosition.TOPRIGHT }: MtToastProps) =>
    toast.error(msg, { position, ...mtToastStyle }),
  warn: ({ msg, position = MtToastPosition.TOPRIGHT }: MtToastProps) =>
    toast.custom(msg, {
      position,
      ...mtToastStyle,
    }),
}

// Format Date
export const formatDate = (date: string) => {
  const convertDate = new Date(date)

  const formattedTime = convertDate.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })

  const getDate = convertDate.toLocaleDateString('en-US', {
    day: 'numeric',
  })

  // Format and get the right date, month and year format
  const formatDate = dateOrdinal(+getDate)

  const formattedMonth = convertDate.toLocaleDateString('en-US', {
    month: 'long',
  })
  const formattedYear = convertDate.toLocaleDateString('en-US', {
    year: 'numeric',
  })

  // Format the date with spacing neccassary
  const formattedDate = `${formatDate}\u00A0\u00A0${formattedMonth}\u00A0\u00A0${formattedYear}`

  return {
    date: formattedDate,
    time: formattedTime,
  }
}

// Date Ordinal
export const dateOrdinal = (date: number) => {
  if (date === 31 || date === 21 || date === 1) return date + 'st'
  else if (date === 22 || date === 2) return date + 'nd'
  else if (date === 23 || date === 3) return date + 'rd'
  else return date + 'th'
}

// Function that handles HTTP error pop up
export function showError(message: string) {
  TherapistToast.error({ msg: message })
}

export function successHandler(message: string) {
  TherapistToast.success({ msg: message })
}

// ERROR handling
export function errorHandler(e: any) {
  const httpError = e as HttpError
  const data = httpError.getData()
  const statusCode = httpError.getStatusCode()

  if (statusCode === HttpStatus.FormFieldError) {
    let errors = data?.errors as Record<string, string[]>
    Object.entries(errors).forEach((error) => {
      showError(error[1][0])
    })
  } else {
    showError(data?.message)
  }
  return data
}

export const isElement = (element: any) => {
  return React.isValidElement(element)
}

// FORMAT AND GET COUNTRY CODES
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

export const formatPhoneNumber = (phone: string, code: string) => {
  const number = phoneUtil.parseAndKeepRawInput(phone, code)

  const countryCode = number.getCountryCode()

  const phoneNumber = number.getNationalNumber()

  return {
    countryCode,
    phoneNumber,
  }
}


/**
 * Removes the leading plus sign from a phone number if it exists.
 *
 * @param phoneNumber - The phone number string which may start with a plus sign.
 * @returns The phone number string without the leading plus sign.
 */
export const removeLeadingPlus = (phoneNumber: string): string => {
  if (phoneNumber.startsWith('+')) {
    return phoneNumber.substring(1);
  }
  return phoneNumber;
};

