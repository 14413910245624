import HttpService, { HttpMethod } from './HttpService'
import StorageService from './StorageService'

export interface SignInDataProps {
  email: string
  password: string
}

export interface SignUpDataProps {
  email: string
  password: string
  firstname: string
  lastname: string
  gender: string
  location: {
    location_city: string
    location_state: string
    location_country: string
  }
  bio: string
  role: string
  phone: string
  phone_region: string
  linked_url: string
  specialized_in: string
  years_of_experience: number
  qualifications: string
  password_confirmation: string
  accept_terms: boolean
}

export default class AuthenticationService {
  constructor(
    private httpService: HttpService,
    private storageService: StorageService
  ) {}

  async signIn(data: SignInDataProps) {
    this.storageService.clearAuthData()
    const { authentication: signInResponse } =
      await this.httpService.fireRequest({
        url: 'login',
        method: HttpMethod.POST,
        body: data,
      })
    this.storageService.saveAuthData({
      access_token: signInResponse.token,
    })
    return signInResponse
  }

  async signUp(data: SignUpDataProps) {
    this.storageService.clearAuthData()
    return await this.httpService.fireRequest({
      url: 'register',
      method: HttpMethod.PUT,
      body: data,
    })
  }

  async signOut() {
    this.storageService.clearAuthData()
    return await this.httpService.fireRequest({
      url: 'logout',
      method: HttpMethod.POST,
    })
  }
}
