import * as Yup from 'yup'

export const inputSchema = Yup.object({
  first_name: Yup.string().required('Please input your first name').max(75),
  last_name: Yup.string().required('Please input your last name').max(75),
  gender: Yup.string().required('Gender is required'),
  username: Yup.string().required('Please input a username').max(75),
  state: Yup.string().required('Please input your state').max(75),
  country: Yup.string().required('Please input your country').max(75),
  city: Yup.string().required('Please input your city').max(75),
  email: Yup.string().required('Email is required').email('Email is invalid'),
  password: Yup.string()
    .min(8, 'password must be greater than or equals 8 character')
    .required('Password is required'),
  phone_number: Yup.string().required('Phone number is required'),
  phone_region: Yup.string().required('Select your country'),
  password_confirmation: Yup.string()
    .required('Please retype your password.')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export const stepTwoSchema = Yup.object({
  bio: Yup.string()
    .required('Please enter a bio')
    .min(100, 'Text must be greater than 100 characters ')
    .max(2500, 'Text can not be more than  2500 characters '),
  role: Yup.string().required('Role is required'),
  years_of_experience: Yup.number().min(0, 'Minimum years of experience is 0'),
  qualifications: Yup.array().min(1, 'Please add atleast one qualification'),
  specialized_in: Yup.array().min(1, 'Please add atleast one specialization'),
  languages: Yup.array().min(1, 'Please add atleast one language'),

  linked_url: Yup.string().required('LinkedIn profile url is required'),
  email: Yup.string().email('Email is invalid'),
})

export const loginSchema = Yup.object({
  email: Yup.string().required('Email is required').email('Email is invalid'),
})

export const changePasswordSchema = Yup.object({
  currentPassword: Yup.string()
    .min(8, 'password must be greater than or equals 8 character')
    .required('Password is required'),
  newPassword: Yup.string()
    .min(8, 'password must be greater than or equals 8 character')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(8, 'password must be greater than or equals 8 character')
    .required('Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match'),
})

export const bankSchema = Yup.object({
  bankName: Yup.string().required('Bank name is required'),
  accountNumber: Yup.string()
    .required('Account Number is required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(10, 'Account number must be 10 digits')
    .max(10, 'Account number must be 10 digits'),
})

export const withdrawSchema = Yup.object({
  amount: Yup.number()
    .required('Amount is required')
    .min(10000, 'Minimum withdrawal is 10,000'),
})

export const sessionSchema = Yup.object({
  discount_limit: Yup.number().when('discount', {
    is: true,
    then: Yup.number()
      .min(1, 'Minimum free session of 1')
      .max(10, 'Maximum free session per month is 10')
      .required('This field is required when discount is enabled.'),
  }),
})
